import React, { useState, createContext, useEffect } from 'react'
import axios from 'axios'
export const GeoContext = createContext()

const BRAND_EMD = 'Merck KGaA, Darmstadt, Germany'
const BRAND_MERCK = 'Merck'

const GeoState = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [isUS, setIsUS] = useState(null)
  const [brand, setBrand] = useState(BRAND_EMD)
  const [url, setUrl] = useState()

  useEffect(() => {
    const queryLocation = async () => {
      try {
        const { data } = await axios.get(
          'https://5jo58ujmw0.execute-api.eu-central-1.amazonaws.com/production/location'
        )

        setIsUS(['US', 'CA'].includes(data.location.country.iso_code))
      } catch (_) {
        setIsUS(true)
      }

      setLoading(false)
    }

    queryLocation()
  }, [])

  useEffect(() => {
    if (isUS !== null) {
      setBrand(isUS ? BRAND_EMD : BRAND_MERCK)
      setUrl(isUS ? 'emd' : 'merck')
    }
  }, [isUS])

  return (
    <GeoContext.Provider value={{ loading, isUS, brand, url }}>
      {children}
    </GeoContext.Provider>
  )
}

export default GeoState
