import React from 'react'
import AppState from './state/appState'
import SearchState from './state/search'
import GeoState from './state/geo'

const GlobalContextProvider = ({ element }) => {
  return (
    <GeoState>
      <AppState>
        <SearchState>{element}</SearchState>
      </AppState>
    </GeoState>
  )
}

export default GlobalContextProvider
