import React, { useState, createContext } from 'react'
import pickBy from 'lodash/pickBy'
import mediaQuery from '../styles/mediaQuery/mediaQuery'
export const AppContext = createContext()

const defaultState = {
  bannerSearchVisible: false, // indicates if search bar on banner is in view
  navOpen: false, // if nav bar is open
  isSearchFocused: false, // if search input focused
  isMobile: null,
  breakpoint: null, // current breakpoint
}

const AppState = ({ children }) => {
  const [state, setState] = useState(defaultState)
  React.useEffect(() => {
    const mq = pickBy(mediaQuery, q => window.innerWidth > q)
    const maxWidth = Math.max(...Object.values(mq))
    const breakpoint = Object.keys(mq).find(key => maxWidth === mq[key])
    setState(state => ({
      ...state,
      isMobile: window.innerWidth < mediaQuery.m,
      breakpoint,
    }))
  }, [])
  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  )
}

export default AppState
