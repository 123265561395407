// If you change these, make sure to also change the according variables in mediaQuery.global.sass
export default {
  xxxs: 1,
  xxs: 320 - 1,
  xs: 376 - 1,
  s: 480 - 1,
  m: 768 - 1,
  l: 1024 - 1,
  xl: 1260 - 1,
  xxl: 1750 - 1,
}
