import React, {
  useState,
  createContext,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Fuse from 'fuse.js'
import mapMethodQueryResults from '../utils/mapMethodQueryResults'

const options = {
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  includeMatches: true,
  findAllMatches: true,
  minMatchCharLength: 1,
  // location: 0,
  threshold: 0.4,
  // distance: 100,
  useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys: [
    'keywords',
    'name',
    'headline',
    'summary',
    'tags',
    'categories',
    'extraTags',
    'difficulty',
    'description',
  ],
}

const methodsQuery = graphql`
  query Search {
    directus {
      workshop_bundles {
        bundles {
          bundle_id {
            name
            description
            methods {
              method_id {
                ...fullMethodFragment
              }
            }
          }
        }
      }
      method(limit: -1) {
        ...fullMethodFragment
      }
    }
  }

  fragment fullMethodFragment on DirectusData_method {
    name
    logo {
      ...imageFragment
    }
    headline
    categories {
      category_id {
        slug
      }
    }
    slug
    steps {
      content
    }
    summary
    keywords
    tags
    extra_tags
    participants_from
    participants_to
    duration_from
    duration_to
    gallery {
      directus_files_id {
        ...imageFragment
      }
    }
    difficulty
  }

  fragment imageFragment on DirectusData_directus_files {
    id
    imageFile {
      publicURL
    }
  }
`

export const SearchContext = createContext()

const SearchState = ({ children }) => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [templatesFound, setTemplatesFound] = useState(false)
  const fuseRef = useRef()
  const templateFuseRef = useRef()

  const data = useStaticQuery(methodsQuery)

  useEffect(() => {
    fuseRef.current = new Fuse(mapMethodQueryResults(data), options)
    templateFuseRef.current = new Fuse(
      data.directus.workshop_bundles?.bundles.map(
        bundleItem => bundleItem?.bundle_id
      ),
      options
    )
  }, [])

  const search = useCallback(
    q => {
      if (fuseRef.current) {
        return fuseRef.current.search(q)
      }
      return []
    },
    [fuseRef.current]
  )

  useEffect(() => {
    if (query.length > 2 && fuseRef.current) {
      const fuseResults = fuseRef.current.search(query)
      const directTemplatesHit =
        templateFuseRef.current.search(query).length > 0
      const templatesMethodHit =
        fuseResults.findIndex(({ item }) =>
          data.directus.workshop_bundles?.bundles?.find(bundleItem =>
            bundleItem?.bundle_id?.methods?.some(
              methodItem => methodItem?.method_id?.slug === item.slug
            )
          )
        ) >= 0
      setResults(fuseResults)
      setTemplatesFound(directTemplatesHit || templatesMethodHit)
    }
  }, [query])

  return (
    <SearchContext.Provider
      value={{ query, setQuery, results, search, templatesFound }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export default SearchState
