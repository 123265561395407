export const mapMethod = ({
  categories,
  duration_from,
  duration_to,
  extra_tags,
  figjam,
  gallery,
  logo,
  participants_from,
  participants_to,
  resources,
  steps,
  tools,
  ...method
}) => ({
  ...method,
  categories: categories?.map(category => category?.category_id?.slug),
  download: { figjam },
  duration: {
    from: duration_from,
    to: duration_to,
  },
  extraTags: extra_tags,
  gallery: gallery?.map(
    galleryItem => galleryItem?.directus_files_id?.imageFile?.publicURL
  ),
  image: gallery?.[0]?.directus_files_id?.imageFile?.publicURL,
  participants: {
    from: participants_from,
    to: participants_to,
  },
  resources: resources?.map(resource =>
    resource
      ? {
          ...resource?.resource_id,
          icon: resource?.resource_id?.icon?.imageFile?.publicURL,
        }
      : null
  ),
  steps: steps?.map(step => step?.content),
  thumb: logo?.imageFile?.publicURL,
  tools: tools?.map(tool =>
    tool
      ? {
          ...tool?.tool_id,
          icon: tool?.tool_id?.icon?.imageFile?.publicURL,
        }
      : null
  ),
})

export const mapMethodQueryResults = data => {
  return data.directus.method.map(mapMethod)
}

export default mapMethodQueryResults
