import Provider from './src/GlobalContextProvider'

// Polyfills for IE11 to support .closest used in search
// and accordion

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this

    do {
      if (Element.prototype.matches.call(el, s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}

export const wrapRootElement = Provider

export const onClientEntry = () => {
  window.__LD_ASSET_PATH__ = window.location.origin + '/liquid/'
}
